






















import Vue from "vue";
import { EmailVerified } from "@/interfaces/auth/email_verified";
import router from "@/router";
import { UserState } from "@/store/modules/auth/interfaces";
import { USER_STATE } from "@/store/modules/auth/constants";
import { mapMutations } from "vuex";
export default Vue.extend({
  name: "EmailVerificationSuccess",
  data(): EmailVerified {
    return {
      decoration_img_src: require("@/assets/illustrations/mail.svg")
    };
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    async proceed_to_onboarding() {
      this.update_user_state(UserState.ONBOARDING_COMPLETED);
      await router.push("/approval-pending");
    }
  }
});
